<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="justify-center d-flex">
        <v-col lg="10" class="mx-auto">
          <v-expansion-panels flat color="#ddd">
            <v-expansion-panel
              v-for="panel in panels"
              :key="panel.title"
              class="bg-transparent"
            >
              <v-expansion-panel-header>
                <h5 class="text-h5 font-weight-bold text-typo">
                  {{ panel.title }}
                </h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="text-body text-sm opacity-8">
                  {{ panel.description }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "volunteer-faq",
  data() {
    return {

    };
  },
  computed:{
    panels(){
      return [
        {
          title: this.$translator.trans("How do I communicate with the refugee?"),
          description:
              this.$translator.trans("Please use the “Messages” section to communicate with your assigned refugee;"),
        },
        {
          title: this.$translator.trans("What should I not share?"),
          description:
              this.$translator.trans("We recommend not sharing private information such as credit card, address or other confidential data;"),
        },
        {
          title: this.$translator.trans("What do I do if they ask me to help them with accommodation?"),
          description:
              this.$translator.trans("We recommend not housing any refugees in your home without further verifications;"),
        },
        {
          title: this.$translator.trans("What are the preliminary checks?"),
          description:
              this.$translator.trans("The Path Forward doesn't provide refugee screening, therefore it is under our volunteer’s decision what level of support they might want to offer;"),
        },
        {
          title: this.$translator.trans("What do I do if they ask me something I don't know?"),
          description:
              this.$translator.trans("If the refugee requests information that you may not provide, please either check with the other volunteers if they are able to support, or redirect the refugee towards other local organisations;"),
        },
        {
          title: this.$translator.trans("What do I do if someone requests money?"),
          description:
              this.$translator.trans("If the refugee requests money please kindly explain that our platform doesn't offer monetary support;"),
        },
      ];
    }
  }
};
</script>
